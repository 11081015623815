import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Row } from "react-bootstrap"
import FuturePay from "../components/cases/futurepay"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import "./mainpage.scss"

const FuturePayPage = ({ location }) => {
    const { t, i18n } = useTranslation("futurepay")
    return (
        <Layout lang={i18n.resolvedLanguage}>
            <div className="max-container-pages">
                <Seo title={t("title")} lang={i18n.resolvedLanguage} description={t("seo.description", { ns: 'common' })} />
                <Row>
                    <FuturePay previous={location.state?.from ?? '/'} ></FuturePay>
                </Row>
            </div>
        </Layout>
    )
}

export default FuturePayPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common","futurepay"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
