import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Button, Col, Container, Row } from 'react-bootstrap'
import ButtonContact from "../layout/buttoncontact"
import { CloseCase } from "./CloseCase"
import "./futurepay.scss"


const FuturePay = ({ className, previous }) => {
    const { t } = useTranslation("futurepay")
    return (
        <section className={(className ? className + " " : "") + "futurepay"}>
            <Container fluid>
                <Row className="reverse imageSection">
                    <Col className="" xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="bg-futurepay"></div>
                    </Col>
                    <Col className="tell-project-futurepay" xl={6} lg={6} md={6} sm={12} xs={12}>
                        <p>
                            <div className="logo-futurepay"></div>
                            <h5 className="">{t("A better alternative to credit cards")}</h5>
                            <a href="https://futurepay.com/" target='_blank' rel="noreferrer"><Button className="btn-success text-nowrap">{t("Learn More About FuturePay")}</Button></a>
                        </p>
                    </Col>
                </Row>
                <div className="max-container-future-pay">
                    <CloseCase referrer={previous}/>
                    <Row className="reverse-column">
                        <Col className="" xl={6} lg={6} md={6} sm={12} xs={12}>
                            <p>
                                <h6 className="">{t("About FuturePay")}</h6> <br></br>
                                {t("Future Pay – Put it in MyTab™ is a frictionless online payment option that lets customers buy now and pay later")}
                            </p>
                        </Col>
                    </Row>
                    <Row className="reverse-column">
                        <Col className="" xl={6} lg={6} md={6} sm={12} xs={12}>
                            <p>
                                <h6 className="">{t("Our Contribution")}</h6><br></br>
                                <h4 className="bullet-culture black-two">{t("Management & Architecture")}</h4>
                                <h4 className="bullet-culture black-two ">{t("Front-end Development")}</h4>
                                <h4 className="bullet-culture black-two ">{t("Back-end Development")}</h4>
                                <h4 className="bullet-culture black-two ">{t("DevSecOps")}</h4>
                                <h4 className="bullet-culture black-two ">{t("Aided in UX/UI Design")}</h4>
                                <h4 className="bullet-culture black-two ">{t("QA and Automation")}</h4>
                            </p>
                        </Col>
                    </Row>

                    <Row className="reverse-column">
                        <Col className="contactus-case" xl={6} lg={6} md={6} sm={12} xs={12}>
                            <p>
                                <h3 className="">{t("Got a Project or Partnership in Mind")}</h3>
                                <ButtonContact title={t("Contact Us")}>{t("Contact Us")} 	<div className="Polygon-9"></div></ButtonContact>
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section >
    )
}

export default FuturePay








